<template>
  <b-card title="Import Data Kehadrian">
    <div>
      <validation-observer ref="accountRules" tag="form">
        <b-form @submit.prevent>
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                Data Import
              </h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <validation-provider #default="{ errors }" name="template" rules="required">
                <b-form-group label="Pilih Template" label-for="template" :state="errors.length > 0 ? false : null"
                  label-cols-md="4">
                  <v-select id="template" v-model="dataTemplate.template" :reduce="(template) => template.value"
                    placeholder="Pilih Template" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataIsActive" label="text" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col offset-md="12">
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-primary"
                @click="DownloadTemplate()">
                Download Template
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b-form-group label="Upload File" label-for="files" label-cols-md="4">
                <validation-provider #default="{ errors }" name="file" rules="required">
                  <b-form-file id="file" v-model="databody.file" :state="errors.length > 0 ? false : null"
                    accept=".xlsx" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col offset-md="12">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
                @click="formSubmitted">
                Upload
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary">
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <b-row>
          <table id="my-table" class="table table-bordered table-striped">
            <!-- The table headers and body will be generated dynamically using JavaScript -->
          </table>
        </b-row>

        <baris></baris>

        <b-row>
          <table id="my-logs" class="table table-bordered table-striped">
            <!-- The table headers and body will be generated dynamically using JavaScript -->
          </table>
        </b-row>

      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup, BForm, BButton,
  BFormFile,
  BFormSelect,
} from 'bootstrap-vue'
// import { codeIcon } from './code'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    ValidationProvider,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormGroup,
    BForm,
    BButton,
    BFormFile,
    BFormSelect,
    vSelect,
  },
  data() {
    return {
      selected: null,
      options: [
        { value: 'employee', text: 'Karyawan' },
      ],
      dataTemplate: {
        template: '',
      },
      databody: {
        type: 'pegawai',
        file: '',
      },
      dataIsActive: [
        { value: 'employee-presence', text: 'Kehadiran Pegawai' },
      ],
    }
  },
  methods: {
    async DownloadTemplate() {
      try {
        const templateid = this.dataTemplate.template;
        console.log(templateid);
        const response = await this.$http.get(`/downloadtemplates/${templateid}`)
        console.log(response.data.data.url);
        window.open(response.data.data.url, "_blank");
      } catch (err) {
        console.log(err)
      }
    },

    async formSubmitted() {
      try {
        const dataFormParent = new FormData()
        Object.keys(this.databody).forEach(key => {
          dataFormParent.append(key, this.databody[key])
        })
        var dynamicUrl = 'employeepresences/import';
       
        const response = await this.$http.post(dynamicUrl, dataFormParent)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Import Data Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'success',
          },
        })
        //this.$router.push({ name: 'import-data-pegawai' })

        console.log(response.data)
        const table = document.getElementById('my-table');

        // Reset the table header by removing the existing thead element (if it exists)
        const existingThead = table.querySelector('thead');
        if (existingThead) {
          table.removeChild(existingThead);
        }

        // Reset the table body by removing the existing tbody element (if it exists)
        const existingTbody = table.querySelector('tbody');
        if (existingTbody) {
          table.removeChild(existingTbody);
        }

        const tablelog = document.getElementById('my-logs');

        // Reset the table header by removing the existing thead element (if it exists)
        const existingTheadlogs = tablelog.querySelector('thead');
        if (existingTheadlogs) {
          tablelog.removeChild(existingTheadlogs);
        }

        // Reset the table body by removing the existing tbody element (if it exists)
        const existingTbodylogs = tablelog.querySelector('tbody');
        if (existingTbodylogs) {
          tablelog.removeChild(existingTbodylogs);
        }

        // Data untuk tabel Anda (Anda dapat memodifikasi ini sesuai kebutuhan)
        if (response.data.data.errors > 0) {
          const tableData = [
            {
              Keterangan: 'Error Data Upload',
              'Total File': 0,
              Sukses: 0,
              Error: response.data.data.errors,
            },

          ];
          // Buat header tabel
          const thead = table.createTHead();
          const headerRow = thead.insertRow();
          for (const key in tableData[0]) {
            const th = document.createElement('th');
            th.textContent = key;
            headerRow.appendChild(th);
          }

          // Buat isi tabel
          const tbody = table.createTBody();
          tableData.forEach((rowData) => {
            const row = tbody.insertRow();
            for (const key in rowData) {
              const cell = row.insertCell();
              cell.textContent = rowData[key];
            }
          });
        } else {
          this.tableData = [];
          this.logsData = [];

          const tableData = [
            {
              Keterangan: 'Total Data',
              'Total': response.data.data.total,
              Sukses: response.data.data.success,
              Error: response.data.data.errors,
            },
            // Tambahkan baris lain sesuai kebutuhan

          ];

          // Buat header tabel
          const thead = table.createTHead();
          const headerRow = thead.insertRow();
          for (const key in tableData[0]) {
            const th = document.createElement('th');
            th.textContent = key;
            headerRow.appendChild(th);
          }

          // Buat isi tabel
          const tbody = table.createTBody();
          tableData.forEach((rowData) => {
            const row = tbody.insertRow();
            for (const key in rowData) {
              const cell = row.insertCell();
              cell.textContent = rowData[key];
            }
          });

          const logsData = response.data.data.logs;
          // Buat header tabel
          const theadlogs = tablelog.createTHead();
          const headerRowlogs = theadlogs.insertRow();
          for (const key in logsData[0]) {
            const th = document.createElement('th');
            th.textContent = key;
            headerRowlogs.appendChild(th);
          }

          // Buat isi tabel
          const tbodylogs = tablelog.createTBody();
          logsData.forEach((rowData) => {
            const row = tbodylogs.insertRow();
            for (const key in rowData) {
              const cell = row.insertCell();
              cell.textContent = rowData[key];
            }
          });


        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Import Data Gagal',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        console.log(err)
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
